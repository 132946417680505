@import '../../../styles/common/common.scss';

.container {
    gap: rem(40px);
    flex-wrap: wrap;

    @include bp(md) {
        gap: rem(20px);
    }

    .social_item {

        svg {
            width: rem(21px);
            height: rem(21px);
        }

        &:hover {
            svg, path {
                fill: var(--utmb-color-secondary);
            }
        }
    }
}
